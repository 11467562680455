// Migrated
<template lang="pug">
#home-trips.col-12.mb-4.mb-md-5.mt-4
  ScreenWidthProvider(v-slot="{ isNarrower, isWider }")
    .d-none.d-md-block(v-if="isWider('md', 1) || !hydrated")
      Swiper(v-bind="sliderOptions")
        Trip(
          v-for="(trip, index) in tripsWithoutFirst"
          :key="`t-${index}`"
          :trip="trip"
        )

    .d-block.d-md-none(v-if="isNarrower('md', 1) || !hydrated")
      Trip(
        v-for="(trip, index) in mobileTripsOutSlide"
        :key="`t-${index}`"
        :trip="trip"
      )
      Swiper(v-bind="sliderOptions")
        Trip(
          v-for="(trip, index) in mobileTripsInSlide"
          :key="`t-${index}`"
          :trip="trip"
        )
</template>

<script>
import { mapState } from 'pinia'

import { SCREEN_WIDTH_SIZES } from '@/constants/screen'

export default defineNuxtComponent({
  data () {
    return {
      mobileTripsOutsideSlideCount: 2,
      sliderOptions: {
        slidesPerView: 1,
        pagination: false,
        spaceBetween: 30,
        constantDisplayNavigation: true,
        navigation: true,
        arrowsType: 'circular',
        loadingSkeletonAnimation: 'skeleton-slides',
        loadingSkeletonAspect: false,
        loadingSkeletonHeight: '400px',
        loop: true,
        onlyRenderVisibleSlides: true,
        preventSwiperUpdate: true,
        autoplay: {
          delay: 5000
        },

        breakpoints: {
          [SCREEN_WIDTH_SIZES.md]: {
            slidesPerView: 3,
          }
        }
      }
    }
  },

  computed: {
    ...mapState(useTripStore, {
      trips: 'categoryStart'
    }),

    firstTrip () {
      return this.trips?.[0] || null
    },

    tripsWithoutFirst () {
      return (this.trips || []).filter(trip => trip.id !== this.firstTrip.id)
    },

    mobileTripsOutSlide () {
      return this.tripsWithoutFirst.slice(0, this.mobileTripsOutsideSlideCount)
    },

    mobileTripsInSlide () {
      return this.tripsWithoutFirst.slice(this.mobileTripsOutsideSlideCount)
    }
  },

  created () {
    this.hydrated = false
  },

  mounted () {
    this.hydrated = !!process.browser
  }
})
</script>

<style lang="scss" scoped>
.trip-item {
  min-height: 400px !important;
}
</style>
